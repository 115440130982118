import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const medicalTreatmentSlice = createSlice({
  name: "medicalTreatment",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
      state.error = null;
    },
    error(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setList(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    statusData(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action.payload?.data?.id
      )[0];
      const index = state.data.indexOf(foundProduct);
      state.data[index] = { ...state.data[index], ...action.payload?.data };
      state.loading = false;
    },
    assignData(state, action) {
      const foundProduct = state.data.filter(
        (e) => e.id === action.payload?.id
      )[0];

      const index = state.data.indexOf(foundProduct);

      state.data[index] = {
        ...state.data[index].medicalSupplier,
        ...action.payload?.data?.freelanceSuppliers,
      };
      state.loading = false;
    },
  },
});

export const { loading, error, setList, assignData ,statusData} =
  medicalTreatmentSlice.actions;

export default medicalTreatmentSlice.reducer;
