import axios from "axios";
import { postRequest, putRequest } from "../connection/network";

const assignApi = "medicalTreatment/assign";
const statusApi = "medicalTreatment/status";

export const getService = async (id) => {
  const token = localStorage.getItem("token");
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/medicalTreatment`, {
      params: { medicalTreatmentId: id },
      headers: {
        ...(token ? { Authorization: token ? token : undefined } : {}),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        `Error retrieving MedicalTreatment data: ${error.message}`
      );
    });
};
export const changeStatusService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");

    let {
      data: { data, message },
    } = await putRequest({
      token,
      body: sendData,
      endPoint: statusApi,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const assignFreelanceSupplierService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");

    let {
      data: { data, message },
    } = await postRequest({
      token,
      body: sendData,
      endPoint: assignApi,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};

export const assignAdminService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");

    let {
      data: { data, message },
    } = await putRequest({
      token,
      body: sendData,
      endPoint: assignApi,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Unknown error occured";
    return { error: sError };
  }
};
